import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import {Welcome} from './pages/welcome';
import {ErrorPage} from './pages/error';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Welcome />,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  return (<RouterProvider router={router} />);
}

export default App;
