import React from 'react';
import {Row} from 'react-bootstrap';
import '../css/welcome.css';

export const Welcome = () => {
    return  <div className="welcome-content">
                <div className="welcome-video">
                    <video poster="https://images.ctfassets.net/xit7f234flxz/6MBHvLYHab6Rip2UvEbSgo/90b8be42b3db6acac5db7a5257834504/woman.jpg" width="100%" height="100%" playsinline>
                        <source type="video/webm" src="https://videos.ctfassets.net/xit7f234flxz/7BqsHwcJ0cjnSY77lhYyO7/5d5d9c6688a791d21cc651fa54f2896c/woman.webm"/>
                        <source type="video/mp4" src="https://videos.ctfassets.net/xit7f234flxz/1PO5rcY0JekmSu3nthvwOd/840fca76899602b16dd2732e68789773/woman.mp4"/>
                    </video>
                </div>
                <div className="welcome-section">
                    <Row>
                        <div className="welcomeText">Welcome to Yasmine's baby shower</div>
                        <div className="suggestBabyNameBtn">Suggest a baby name</div>
                    </Row>
                </div>
            </div>
}